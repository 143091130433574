import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PartnerModel } from '../../models/partner.model';
import { UserModel } from '../../models/partner-user.model';

const API_USERS_URL = environment.base_url;

@Injectable({
  providedIn: 'root',
})
export class PartnerHTTPService {
  constructor(private http: HttpClient) {}

  // public methods
  login(emailId: string, password: string): Observable<any> {
    return this.http.post<PartnerModel>(
      `${API_USERS_URL}/payoutPartner/login`,
      {
        emailId,
        password,
      }
    );
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(
      `${API_USERS_URL}/payoutPartner/signUp`,
      user
    );
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string, sendOtpTo: string): Observable<boolean> {
    return this.http.post<boolean>(
      `${API_USERS_URL}/payoutPartner/forgotPassword`,
      {
        email,
        sendOtpTo,
      }
    );
  }
  OTP(otp: string, email: string, password: string): Observable<UserModel> {
    return this.http.post<UserModel>(
      `${API_USERS_URL}/payoutPartner/forgotPassword/update`,
      {
        otp,
        password,
        email,
      }
    );
  }

  getUserByToken(token: string): Observable<UserModel> {
    // const httpHeaders = new HttpHeaders({
    //   Authorization: `Bearer ${token}`,
    // });
    return this.http.get<UserModel>(`${API_USERS_URL}/payoutPartner/me`);
  }
}
