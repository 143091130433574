import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  // private loadCount: number = 0;
  // loadState = new BehaviorSubject(false);

  // ShowLoader() {
  //   this.loadCount += 1;
  //   this.loadState.next(true);
  // }

  // HideLoader() {
  //   this.loadCount = this.loadCount ? --this.loadCount : 0;
  //   if (!this.loadCount) {
  //     this.loadState.next(false);
  //   }
  // }
  private count = 0;
  private spinner$ = new BehaviorSubject<string>('');
  constructor() {}
  getSpinnerObserver(): Observable<string> {
    return this.spinner$.asObservable();
  }
  requestStarted() {
    if (++this.count === 1) {
      this.spinner$.next('start');
    }
  }
  requestEnded() {
    if (this.count === 0 || --this.count === 0) {
      this.spinner$.next('stop');
    }
  }
  resetSpinner() {
    this.count = 0;
    this.spinner$.next('stop');
  }
}
