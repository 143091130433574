import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { PartnerService } from './partner/service/partner.service';
import { AdminService } from './Admin/service/admin.service';
import { MsbService } from './auth/services/msb.service';
import { LoaderComponent } from './loader.component';
import { HttpErrorInterceptor } from 'src/http-error.interceptor';
import { HotToastModule } from '@ngneat/hot-toast';
// import { LoaderInterceptor } from './loader.interceptors';

function appInitializer(msbService: MsbService) {
  return () => {
    return new Promise<void>((resolve) => {
      msbService.getUserByToken().subscribe().add(resolve());
    });
  };
}
function appInitializers(partnerService: PartnerService) {
  return () => {
    return new Promise<void>((resolve) => {
      partnerService.getUserByToken().subscribe().add(resolve());
    });
  };
}
function appInitializerss(adminService: AdminService) {
  return () => {
    return new Promise<void>((resolve) => {
      adminService.getUserByToken().subscribe().add(resolve());
    });
  };
}
@NgModule({
  declarations: [AppComponent, LoaderComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // ReactiveFormsModule,
    // FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    HotToastModule.forRoot(),
  ],
  providers: [
    Title,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [MsbService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializers,
      multi: true,
      deps: [PartnerService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerss,
      multi: true,
      deps: [AdminService],
    },
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
