import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AdminService } from 'src/app/Admin';
// import { AsideMenuService } from 'src/app/pages/layout/aside/aside-menu/aside-menu.service';
import { PartnerService } from 'src/app/partner';
import { MsbService } from './msb.service';

@Injectable({ providedIn: 'root' })
export class MsbGuard implements CanActivate {
  constructor(
    private msbService: MsbService,
    private partnerService: PartnerService,
    private adminService: AdminService // private asideService: AsideMenuService
  ) {}
  MSB = 'msb';
  ADMIN = 'admin';
  PP = 'partner';
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentmsbUser = this.msbService.currentUserValue;
    const currentpartnerUser = this.partnerService.currentUserValue;
    const AdmincurrentUser = this.adminService.currentUserValue;
    const msbValue = localStorage.getItem(this.MSB);
    const adminValue = localStorage.getItem(this.ADMIN);
    const PPValue = localStorage.getItem(this.PP);
    // console.log('token', adminValue);
    if (msbValue) {
      // console.log(currentmsbUser);
      // logged in so return true
      return true;
    }
    if (adminValue) {
      // console.log(AdmincurrentUser);
      // logged in so return true
      return true;
    }
    if (PPValue) {
      // console.log(currentpartnerUser);
      // logged in so return true
      return true;
    }
    // if (this.asideService.Checked == true) {
    //   return false;
    // }
    // if (this.asideService.Checked == false) {
    //   return true;
    // }
    // not logged in so redirect to login page with the return url
    this.msbService.logout();
    // this.partnerService.logout();
    return false;
  }
}
