import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import * as bcrypt from 'bcryptjs';
// import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
// import { CookieService } from 'ngx-cookie-service';
import { interval, Observable, of, throwError } from 'rxjs';
import { retry, catchError, tap } from 'rxjs/operators';
import { MsbService } from './app/auth/services/msb.service';
import { AsideMenuService } from './app/pages/layout/aside/aside-menu/aside-menu.service';
import { PartnerService } from './app/partner';
// import { Login_Partnerservice } from './app/admin/login/login.service';
import { environment } from './environments/environment';
import { AdminService } from './app/Admin/service/admin.service';
import { Injectable, Injector } from '@angular/core';
import { LoaderService } from './app/loader.service';
import { MsbDocumentsUploadService } from './app/pages/layout/components/MSB/msb-documents-upload/msb-documents-upload.service';
import { PPDocumentsUploadService } from './app/pages/layout/components/PP/pp-documents-upload/PP-documents-upload.service';
const API_USERS_URL = environment.base_url;
// import * as consts from "./shared/endPoint";
@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    public router: Router,
    private http: HttpClient,
    private injector: Injector, // private adminService: AdminService
    public _MsbDocsUploadService: MsbDocumentsUploadService,
    public _ppDocumentsUploadService: PPDocumentsUploadService
  ) {}

  refresh_url = '';
  refreshToken: any;
  token: any;
  encryptedData: any;
  dataToEncrypy: any;
  TokenUrl: any;
  data: any;
  MSB = 'msb';
  ADMIN = 'admin';
  PARTNER = 'partner';
  ErrorMessage: any;
  changeError: any;
  Method: any;
  refreshedTokenUnderProcess: boolean = false;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const _Msbservice = this.injector.get(MsbService);
    const _AsideMenuService = this.injector.get(AsideMenuService);
    // const _MsbDocsUploadService = this.injector.get(MsbDocumentsUploadService);
    // const _ppDocumentsUploadService = this.injector.get(
    //   PPDocumentsUploadService
    // );
    const _Partnerservice = this.injector.get(PartnerService);
    const _Adminservice = this.injector.get(AdminService);
    const spinnerService = this.injector.get(LoaderService);
    const msbValue = localStorage.getItem(this.MSB);
    const adminValue = localStorage.getItem(this.ADMIN);
    const partnerValue = localStorage.getItem(this.PARTNER);
    // console.log(next.handle(request).subscribe());
    spinnerService.requestStarted();
    const foundChallangeHeader = request.headers
      .keys()
      .find((keyToCheck) => keyToCheck == 'challenge');
    const foundChallangeResultValue = request.headers.get('challenge') ?? '';

    if (!request.url?.toString().includes('refreshToken')) {
      if (!foundChallangeHeader && msbValue && !adminValue) {
        if (
          request.url.toString().includes('msb/transactionBatch/upload') ||
          request.url.toString().includes('profileDocs/upload') ||
          request.url.toString().includes('msb/addInitialPayInBagEvidence') ||
          request.url
            .toString()
            .includes('msb/extraDocumentForTransactionOrBatch') ||
            request.url.toString().includes('attachInvoices') ||
            request.url.toString().includes('attachToInvoice') ||
            request.url.toString().includes('attachBOLToInvoice')
        ) {
          request = request.clone({
            headers: new HttpHeaders({
              'Access-Control-Allow-Origin': environment.allowed_origin,
              Authorization: `Bearer ${_Msbservice.getToken()}`,
            }),
          });
        } else {
          request = request.clone({
            headers: new HttpHeaders({
              'Access-Control-Allow-Origin': environment.allowed_origin,
              'Content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${_Msbservice.getToken()}`,
            }),
          });
        }
      }
      if (!foundChallangeHeader && partnerValue && !adminValue) {
        if (request.url.toString().includes('profileDocs/upload') ||
            request.url.toString().includes('uploadEvidence') ||
            request.url.toString().includes('uploadFile')
        ) {
          request = request.clone({
            headers: new HttpHeaders({
              'Access-Control-Allow-Origin': environment.allowed_origin,
              Authorization: `Bearer ${_Partnerservice.getToken()}`,
            }),
          });
        } else {
          request = request.clone({
            setHeaders: {
              'Access-Control-Allow-Origin': environment.allowed_origin,
              'Content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${_Partnerservice.getToken()}`,
            },
          });
        }
      }
      if (!foundChallangeHeader && adminValue && !msbValue) {
        if (request.url.toString().includes('/admin/pep/uploadPEPData')||
        request.url.toString().includes('uploadEvidence')||
        request.url.toString().includes('admin/cashBag/addInitialPayOutBagEvidence')
        ) {
          request = request.clone({
            headers: new HttpHeaders({
              'Access-Control-Allow-Origin': environment.allowed_origin,
              Authorization: `Bearer ${_Adminservice.getToken()}`,
            }),
          });
        } else {
          request = request.clone({
            headers: new HttpHeaders({
              'Access-Control-Allow-Origin': environment.allowed_origin,
              'Content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${_Adminservice.getToken()}`,
            }),
          });
        }
      }

      if (foundChallangeHeader && msbValue && !adminValue) {
        request = request.clone({
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': environment.allowed_origin,
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${_Msbservice.getToken()}`,
            challengeResult: foundChallangeResultValue,
          }),
        });
      }
      if (foundChallangeHeader && adminValue && !msbValue) {
        request = request.clone({
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': environment.allowed_origin,
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${_Adminservice.getToken()}`,
            challengeResult: foundChallangeResultValue,
          }),
        });
      }
      if (foundChallangeHeader && partnerValue && !adminValue) {
        request = request.clone({
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': environment.allowed_origin,
            'Content-type': 'application/json; charset=UTF-8',
            Authorization: `Bearer ${_Partnerservice.getToken()}`,
            challengeResult: foundChallangeResultValue,
          }),
        });
      } else if (
        request.url.toString().includes('login') ||
        request.url.toString().includes('signUp') ||
        request.url.toString().includes('countries') ||
        request.url.toString().includes('forgotPassword') ||
        request.url.toString().includes('sendOtpOptionController')
      ) {
        request = request.clone({
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin': environment.allowed_origin,
          }),
        });
      }
    } else {
      if (request.url?.toString().includes('refreshToken')) {
        if (adminValue) {
          request = request.clone({
            headers: new HttpHeaders({
              'Access-Control-Allow-Origin': environment.allowed_origin,
              'Content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${_Adminservice.getRefreshToken()}`,
            }),
          });
        }
        if (partnerValue) {
          request = request.clone({
            headers: new HttpHeaders({
              'Access-Control-Allow-Origin': environment.allowed_origin,
              'Content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${_Partnerservice.getRefreshToken()}`,
            }),
          });
        }
        if (msbValue) {
          request = request.clone({
            headers: new HttpHeaders({
              'Access-Control-Allow-Origin': environment.allowed_origin,
              'Content-type': 'application/json; charset=UTF-8',
              Authorization: `Bearer ${_Msbservice.getRefreshToken()}`,
            }),
          });
        }
      }
    }

    // console.log('>>>>>>>>>');

    request.headers.keys().forEach((key) => {
      // console.log(`key : ${key} :: ${request.headers.get(key)}`);
    });

    // console.log('<<<<<<<<<');

    return next.handle(request).pipe(
      retry(1),
      // tap(
      //   (event) => {
      //     if (event instanceof HttpResponse) {
      //       spinnerService.requestEnded();
      //     }
      //   },
      catchError(
        (error: HttpErrorResponse) => {
          let errorMessage = {};
          spinnerService.resetSpinner();
          // console.log()
          if (error.error instanceof ErrorEvent) {
            // client-side error
            // errorMessage = `Error: ${error.error.message}`;
            errorMessage = {
              message: error.error.message,
            };
          } else {
            // server-side error
            // errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
            errorMessage = {
              message: error.error.message,
              code: error.error.code,
            };

            // console.log(error);
            if (error.error.code == 401001) {
              if (
                !error.url?.toString().includes('refreshToken') &&
                adminValue
              ) {
                // console.log(request.method);
                // this.TokenUrl = error.url;
                // this.Method = request.method;
                if (!this.refreshedTokenUnderProcess) {
                  // console.log(`current token >> ${_Adminservice.getToken()}`);
                  this.refreshedTokenUnderProcess = true;
                  this.http.post(`${API_USERS_URL}/refreshToken`, {}).subscribe(
                    (data) => {
                      // console.log(data);
                      localStorage.setItem(
                        _Adminservice.adminLocalStorageToken,
                        JSON.stringify(data)
                      );
                      // window.location.reload();
                      this.refreshedTokenUnderProcess = false;
                    },
                    (err) => {
                      // console.log(err);
                      localStorage.clear();
                      this.router.navigate(['/admin/login']);
                    }
                  );
                  setTimeout(() => {
                    this.refreshedTokenUnderProcess = false;
                  }, 1000);
                } else {
                  // console.log('setting timeout');
                  // this.Method = request.method;
                  setTimeout(() => {
                    // console.log('>>>>>>>');
                    // console.log(`current token >>> ${_Adminservice.getToken()}`);
                    //next.handle(request).subscribe();
                  }, 10);
                }
              } else if (
                !error.url?.toString().includes('refreshToken') &&
                partnerValue
              ) {
                this.TokenUrl = error.url;
                if (!this.refreshedTokenUnderProcess) {
                  this.refreshedTokenUnderProcess = true;
                  this.http.post(`${API_USERS_URL}/refreshToken`, {}).subscribe(
                    (data) => {
                      // console.log(data);
                      localStorage.setItem(
                        _Partnerservice.partnerLocalStorageToken,
                        JSON.stringify(data)
                      );
                      // window.location.reload();

                      this.refreshedTokenUnderProcess = false;
                    },
                    (err) => {
                      localStorage.clear();
                      this.router.navigate(['/payoutpartner/login']);
                    }
                  );
                  setTimeout(() => {
                    this.refreshedTokenUnderProcess = false;
                  }, 1000);
                } else {
                  // console.log('setting timeout');
                  // this.Method = request.method;
                  setTimeout(() => {
                    // console.log('>>>>>>>');
                    //next.handle(request).subscribe();
                  }, 10);
                }
              } else if (
                !error.url?.toString().includes('refreshToken') &&
                msbValue
              ) {
                if (!this.refreshedTokenUnderProcess) {
                  this.refreshedTokenUnderProcess = true;
                  this.http.post(`${API_USERS_URL}/refreshToken`, {}).subscribe(
                    (data) => {
                      // console.log(data);
                      localStorage.setItem(
                        _Msbservice.msbLocalStorageToken,
                        JSON.stringify(data)
                      );
                      // window.location.reload();
                      this.refreshedTokenUnderProcess = false;
                    },
                    (err) => {
                      localStorage.clear();
                      this.router.navigate(['/msb/login']);
                    }
                  );
                  setTimeout(() => {
                    this.refreshedTokenUnderProcess = false;
                  }, 1000);
                } else {
                  // this.Method = request.method;
                  setTimeout(() => {
                    //next.handle(request).subscribe();
                  }, 10);
                }
              } else {
                _Msbservice.logout();
              }
            }
            // if ((this.refreshedToken = true)) {
            //   interval(10000).subscribe((val) => {
            //     console.log('called');
            //   });
            // }
            if (error.error.code == 401001) {
              error.error.message = '';
              errorMessage = '';
              // console.log(error.error.message);
              // return of(undefined);
            }
            if (
              error.error.code == 401002 ||
              error.error.code == 401003 ||
              error.error.code == 401004
            ) {
              _Msbservice.logout();
            }
            if (error.error.code == 401005) {
              error.error.message = '';
              errorMessage = '';
              this.enceypted(
                error.url ?? '',
                error.error.solveChallenge.rounds,
                error.error.solveChallenge.stringToEncrypt
              );
            }
            if (error.error.code == 401008) {
              if (msbValue) {
                this.router.navigate(['/list/msb/myDocuments']);
                this._MsbDocsUploadService.dialog(error.error.message);
              }
              if (partnerValue) {
                this.router.navigate(['/list/payoutPartner/myDocuments']);
                this._ppDocumentsUploadService.dialog(error.error.message);
              }
            }
            if (error.error.code == 401009) {
              this.changeError = error.error.message;
              if (adminValue) {
                this.router.navigate(['/list/admin/password/changePassword']);
              }
              if (msbValue) {
                this.router.navigate(['/list/msb/changePassword']);
              }
              if (partnerValue) {
                this.router.navigate(['/list/payoutPartner/changePassword']);
              }
              _AsideMenuService.byCategory(this.changeError);
            }
            if (error.error.message == undefined && error.error.status == 400) {
              errorMessage = errorMessage = {
                message: 'Bad Request',
                code: error.status,
              };
            }
            if (error.error.message == undefined && error.error.code == 401) {
              errorMessage = errorMessage = {
                message: 'Unauthorized',
                code: error.status,
              };
            }
            if (error.error.message == undefined && error.error.status == 402) {
              errorMessage = errorMessage = {
                message: 'Payment Required',
                code: error.status,
              };
            }
            if (error.error.message == undefined && error.error.status == 404) {
              errorMessage = errorMessage = {
                message: 'Not Found',
                code: error.status,
              };
            }
            if (error.error.message == undefined && error.error.status == 500) {
              errorMessage = errorMessage = {
                message: 'Internal Server Error',
                code: error.status,
              };
            }
            if (error.error.message == undefined && error.error.status == 501) {
              errorMessage = errorMessage = {
                message: 'Not Implemented',
                code: error.status,
              };
            }
            if (error.error.message == undefined && error.error.status == 502) {
              errorMessage = errorMessage = {
                message: 'Bad Gateway',
                code: error.status,
              };
            }
            if (error.error.message == undefined && error.error.status == 503) {
              errorMessage = errorMessage = {
                message: 'Service Unavailable',
                code: error.status,
              };
            }
            if (error.error.message == undefined && error.error.status == 504) {
              errorMessage = errorMessage = {
                message: 'Gateway Timeout',
                code: error.status,
              };
            }
            if (error.error.message == undefined && error.error.status == 505) {
              errorMessage = errorMessage = {
                message: 'Version Not Supported',
                code: error.status,
              };
            }
          }

          // window.alert(error.error.status);
          this.ErrorMessage = errorMessage;
          return throwError(errorMessage);
        }
        // )
      )
    );
  }

  async enceypted(
    challangeSourceUrl: string,
    rounds: number,
    stringToEncrypt: string
  ) {
    const hash = await bcrypt.hash(stringToEncrypt, rounds);
    // console.log('Encrypted :' + hash);
    if (hash) {
      this.http
        .get(challangeSourceUrl, { headers: { challenge: hash } })
        .subscribe((data) => {
          // console.log(data);
          window.location.reload();
        });
    } else {
      return;
    }
  }
}
