import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../models/user.model';
import { environment } from '../../../../environments/environment';
import { MsbModel } from '../../models/msb.model';
import { Injectable } from '@angular/core';

const API_USERS_URL = environment.base_url;

@Injectable({
  providedIn: 'root',
})
export class MsbHTTPService {
  constructor(private http: HttpClient) {}

  // public methods
  login(emailId: string, password: string): Observable<any> {
    return this.http.post<MsbModel>(`${API_USERS_URL}/msb/login`, {
      emailId,
      password,
    });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: any): Observable<any> {
    return this.http.post<MsbModel>(`${API_USERS_URL}/msb/signUp`, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string, sendOtpTo: string): Observable<UserModel> {
    return this.http.post<UserModel>(`${API_USERS_URL}/msb/forgotPassword`, {
      email,
      sendOtpTo,
    });
  }
  OTP(otp: string, email: string, password: string): Observable<UserModel> {
    return this.http.post<UserModel>(
      `${API_USERS_URL}/msb/forgotPassword/update`,
      {
        otp,
        password,
        email,
      }
    );
  }
  getUserByToken(token: string): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      // Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    });
    return this.http.get<UserModel>(`${API_USERS_URL}/msb/me`);
  }
}
