import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PartnerHTTPService } from './partner-http/partner-http.service';
import { PartnerModel } from '../models/partner.model';
import { UserModel } from '../models/partner-user.model';

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class PartnerService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  partnerLocalStorageToken = `PartnerLogin`;

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private partnerHttpService: PartnerHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(emailId: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.partnerHttpService.login(emailId, password).pipe(
      map((auth: PartnerModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      // catchError((err) => {
      //   console.error('err', err);
      //   return of(undefined);
      // }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    localStorage.removeItem(this.partnerLocalStorageToken);
    this.router.navigate(['/payoutpartner/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.partnerHttpService.getUserByToken(auth.token).pipe(
      map((user: UserType) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.partnerHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.emailId, user.password)),
      // catchError((err) => {
      //   // console.error('err', err);
      //   return of(undefined);
      // }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string, sendOtpTo: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.partnerHttpService
      .forgotPassword(email, sendOtpTo)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  otp(otp: string, email: string, password: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.partnerHttpService
      .OTP(otp, email, password)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  PARTNER = 'partner';
  private setAuthFromLocalStorage(auth: PartnerModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem(this.partnerLocalStorageToken, JSON.stringify(auth));
      localStorage.setItem(this.PARTNER, JSON.stringify(this.PARTNER));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): PartnerModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.partnerLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      // console.error(error);
      return undefined;
    }
  }
  getToken() {
    const lsValue = localStorage.getItem(this.partnerLocalStorageToken);
    if (!lsValue) {
      return undefined;
    }
    try {
      const authData = JSON.parse(lsValue);
      return authData.token;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  getRefreshToken() {
    try {
      const lsValue = localStorage.getItem(this.partnerLocalStorageToken);
      // console.log(lsValue);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      // console.log(authData.refreshToken);
      return authData.refreshToken;
    } catch (error) {
      // console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
