import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GlobalErrorHandler } from 'src/global-error-handler';
import { HttpErrorInterceptor } from 'src/http-error.interceptor';
import { MsbGuard } from './auth/services/msb.guard';
import { AlwaysAuthGuard } from './pages/layout/AlwaysAuthGuard.guard';
import { PartnerGuard } from './partner/service/partner.guard';

const routes: Routes = [
  {
    path: 'msb',
    canActivate: [AlwaysAuthGuard],
    loadChildren: () => import('./auth/msb.module').then((m) => m.AuthModule),
  },
  {
    path: 'payoutpartner',
    canActivate: [AlwaysAuthGuard],
    loadChildren: () =>
      import('./partner/partner.module').then((m) => m.PartnerModule),
  },
  {
    path: 'admin',
    canActivate: [AlwaysAuthGuard],
    loadChildren: () =>
      import('./Admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '',
    canActivate: [MsbGuard],
    loadChildren: () =>
      import('./pages/layout/layout.module').then((m) => m.LayoutModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./pages/error/error.module').then((m) => m.ErrorsModule),
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), HttpClientModule],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    DatePipe,
    // {
    //   provide: ErrorHandler,
    //   useClass: GlobalErrorHandler,
    // },
  ],
})
export class AppRoutingModule {}
