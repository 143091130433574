import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserModel } from 'src/app/auth';
import { AsideMenuService } from '../../../aside/aside-menu/aside-menu.service';
const API_USERS_URL = environment.base_url;
export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class PPDocumentsUploadService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = [];
  data: any;
  PP = 'partner';
  ADMIN = 'admin';
  data1: any;
  // public fields
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    public _asideMenuService: AsideMenuService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }
  msgShow: boolean = false;
  Checked: boolean = false;
  dialog(msg: any) {
    if (msg) {
      this.msgShow = true;
      this.Checked = true;
      this._asideMenuService.byCategoryValue =
        this._asideMenuService.byCategoryValue.filter(
          (data: { identifier: string }) => data.identifier == 'DOCUMENTS'
        );
      this._asideMenuService.currentCountrySubject.next(
        this._asideMenuService.byCategoryValue
      );
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
