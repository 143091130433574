import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';
@Component({
  selector: 'loader',
  template: `
    <div *ngIf="showSpinner" class="loaderMask">
      <h1 class="fas fa-spinner fa-spin"></h1>
    </div>
  `,
  styles: [
    '.loaderMask{position: absolute; height: 100%; width: 100%; z-index: 1; background-color: rgba(100, 100, 100, 0.3);display: flex; align-items: center; justify-content: center; font-size: 24px;}',
  ],
})
export class LoaderComponent implements OnInit {
  // show!: boolean;
  showSpinner = false;
  constructor(
    private _loaderService: LoaderService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    // this._loaderService.loadState.subscribe((res) => {
    //   this.show = res;
    // });
    this.init();
  }
  init() {
    this._loaderService.getSpinnerObserver().subscribe((status) => {
      this.showSpinner = status === 'start';
      this.cdRef.detectChanges();
    });
  }
}
// <i class="fas fa-spinner fa-spin" *ngIf="show"></i>
