import { MsbModel } from './msb.model';

export class UserModel extends MsbModel {
  //  id!: number;
  username!: string;
  password!: string;
  name!: string;
  emailId!: string;
  mobileNumberCountryCode!: string;
  mobileNumber: number[] = [];
  address!: string;
  // country!: string;
  mlroEmailId!: string;
  country!: string;
  status: any;
  // data: Piece[] = [];
  // data:Array<any>=[this.officialName]
  emailIdVerified!: string;
  mobileNumberVerified!: string;
  officialName!: string;
  documentName!: string;
  documentStatus!: string;
  createdDate!: string;
  documentId!: string;
  // personal information
  firstname!: string;
  lastname!: string;
  website!: string;
  // account information
  language!: string;
  timeZone!: string;
  communication!: {
    email: boolean;
    sms: boolean;
    phone: boolean;
  };
  // email settings
  data?: {
    id: string;
    officialName: string;
    alpha3: string;
    // activityRelatesEmail: {
    //   youHaveNewNotifications: boolean;
    //   youAreSentADirectMessage: boolean;
    //   someoneAddsYouAsAsAConnection: boolean;
    //   uponNewOrder: boolean;
    //   newMembershipApproval: boolean;
    //   memberRegistration: boolean;
    // };
    // updatesFromKeenthemes: {
    //   newsAboutKeenthemesProductsAndFeatureUpdates: boolean;
    //   tipsOnGettingMoreOutOfKeen: boolean;
    //   thingsYouMissedSindeYouLastLoggedIntoKeen: boolean;
    //   newsAboutMetronicOnPartnerProductsAndOtherServices: boolean;
    //   tipsOnMetronicBusinessProducts: boolean;
    // };
  };
  offsetToken!: string;

  setUser(_user: unknown) {
    const user = _user as UserModel;
    // this.id = user.id;
    this.password = user.password || '';
    this.name = user.name || '';
    this.emailId = user.emailId || '';
    this.mobileNumberCountryCode = user.mobileNumberCountryCode || '';
    this.mobileNumber = user.mobileNumber || '';
    this.address = user.address || '';
    this.country = user.country || '';
    this.mlroEmailId = user.mlroEmailId || '';
    // this.data=user.data[id:stri]
  }
}
