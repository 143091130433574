import {
  ChangeDetectorRef,
  Injectable,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Observable,
  BehaviorSubject,
  of,
  Subscription,
  throwError,
  finalize,
  map,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserModel } from 'src/app/auth';
const API_USERS_URL = environment.base_url;
export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AsideMenuService implements OnInit, OnDestroy {
  MSB = 'msb';
  ADMIN = 'admin';
  PP = 'partner';
  ByCategoryItem = 'AllByCategoryItem';
  byCategoryValue: any;
  currentCategoryMethod$!: Observable<any>;
  currentCategoryMethodSubject!: BehaviorSubject<any>;
  currentCountry$: Observable<UserType>;
  currentCountrySubject: BehaviorSubject<UserType>;
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  constructor(
    private http: HttpClient // private changeDetectorRef: ChangeDetectorRef
  ) {
    this.currentCategoryMethodSubject = new BehaviorSubject<any>(undefined);
    this.currentCategoryMethod$ =
      this.currentCategoryMethodSubject.asObservable();
    // const subscri = this.byCategoryMethod().subscribe();
    // this.unsubscribe.push(subscri);
    this.currentCountrySubject = new BehaviorSubject<UserType>(undefined);
    this.currentCountry$ = this.currentCountrySubject.asObservable();
  }
  ngOnInit(): void {
    // this.setChangedetector(this.changeDetectorRef);
  }
  getUserCategory(): Observable<any> {
    const MSBValue = localStorage.getItem(this.MSB);
    const adminValue = localStorage.getItem(this.ADMIN);
    if (MSBValue) {
      return this.http.get<any>(`${API_USERS_URL}/accessControls/byCategory`);
    }
    if (adminValue) {
      return this.http.get<any>(`${API_USERS_URL}/accessControls/byCategory`);
    } else {
      return this.http.get<any>(`${API_USERS_URL}/accessControls/byCategory`);
    }
  }
  get currentCountryValue(): UserType {
    return this.currentCategoryMethodSubject.value;
  }

  set currentCountryValue(user: UserType) {
    this.currentCategoryMethodSubject.next(user);
  }
  data1: any;
  value: any;
  forexadd: any;
  forexedit: any;
  payoutcountryAdd: any;
  payoutcurrencyAdd: any;
  payoutMethodAdd: any;
  payinCountryAdd: any;
  payinCurrencyAdd: any;
  AccessRolesAdd: any;
  AccessControlPolicyAdd: any;
  EventAccessAdd: any;
  CountryAdd: any;
  TransactionBatchAdd: any;
  ApproveDocAdd: any;
  RejectDocAdd: any;
  Approvemsb: any;
  Rejectmsb: any;
  adminDoc: any;
  msbAllTB: any;
  msbChangeRate: any;
  Rejectpp: any;
  Approvepp: any;
  TransactionColumn: any;
  msbColumnAlias: any;
  deliveryCountry: any;
  payoutPlanAdd: any;
  CategoryMethod = new Array<any>();
  changeDetectorRef!: ChangeDetectorRef;
  byCategoryMethod() {
    this.getUserCategory().subscribe((val) => {
      this.data1 = val.data;
      for (let i = 0; this.data1.length > i; i++) {
        for (let j = 0; this.data1[i].accessControls.length > j; j++) {
          // console.log(this.value[i].accessControls[j].method);
          this.CategoryMethod.push(this.data1[i].accessControls[j].name);
        }
      }
    });
    // return this.getUserCategory().pipe(
    //   map((user: any) => {
    //     if (user) {
    //       this.data1 = user.data;
    //       alert(this.data1);
    // let obj = this.data1.forEach(
    //   (element: any) => element.accessControls
    // );

    // let object = obj.find((element: any) => element.name);
    // if (error.status)
    // this.currentCountrySubject.next(this.data1);
    // console.log(this.data1);
    // for (let i = 0; this.data1.length > i; i++) {
    //   this.value = this.data1.filter(
    //     (data: { identifier: string }) =>
    //       data.identifier !== 'DO_NOT_SHOW'
    //   );
    // }
    // for (let i = 0; this.data1.length > i; i++) {
    //   for (let j = 0; this.data1[i].accessControls.length > j; j++) {
    //     // console.log(this.value[i].accessControls[j].method);
    //     this.CategoryMethod.push(this.data1[i].accessControls[j].name);
    //   }
    // }
    // if (this.CategoryMethod.length > 0) {
    //   this.forexadd = this.CategoryMethod.filter(
    //     (data) => data == 'addForexData POST'
    //   );
    //   this.forexedit = this.CategoryMethod.filter(
    //     (data) => data == 'updateForexData PUT'
    //   );
    //   this.payoutcountryAdd = this.CategoryMethod.filter(
    //     (data) => data == 'addPayOutCountry POST'
    //   );
    //   this.payoutcurrencyAdd = this.CategoryMethod.filter(
    //     (data) => data == 'addPayOutCurrency POST'
    //   );
    //   this.payoutMethodAdd = this.CategoryMethod.filter(
    //     (data) => data == 'addPayOutMethod POST'
    //   );
    //   this.payinCountryAdd = this.CategoryMethod.filter(
    //     (data) => data == 'addPayInCountry POST'
    //   );
    //   this.payinCurrencyAdd = this.CategoryMethod.filter(
    //     (data) => data == 'addPayInCurrency POST'
    //   );
    //   this.AccessRolesAdd = this.CategoryMethod.filter(
    //     (data) => data == 'accessRole POST'
    //   );
    //   this.AccessControlPolicyAdd = this.CategoryMethod.filter(
    //     (data) => data == 'accessControlPolicy POST'
    //   );
    //   this.EventAccessAdd = this.CategoryMethod.filter(
    //     (data) => data == 'connectEventWithAccessRole POST'
    //   );
    //   this.CountryAdd = this.CategoryMethod.filter(
    //     (data) => data == 'addCountry POST'
    //   );
    //   this.TransactionBatchAdd = this.CategoryMethod.filter(
    //     (data) => data == 'postTransactionBatch POST'
    //   );
    //   this.ApproveDocAdd = this.CategoryMethod.filter(
    //     (data) => data == 'approveDocument POST'
    //   );
    //   this.RejectDocAdd = this.CategoryMethod.filter(
    //     (data) => data == 'rejectDocument POST'
    //   );
    //   this.Approvemsb = this.CategoryMethod.filter(
    //     (data) => data == 'msbApprove POST'
    //   );
    //   this.Rejectmsb = this.CategoryMethod.filter(
    //     (data) => data == 'msbReject POST'
    //   );
    //   this.adminDoc = this.CategoryMethod.filter(
    //     (data) => data == 'getDocuments GET'
    //   );
    //   this.msbAllTB = this.CategoryMethod.filter(
    //     (data) => data == 'getAllTransactionBatch GET'
    //   );
    //   this.msbChangeRate = this.CategoryMethod.filter(
    //     (data) => data == 'changeRateForMsb PUT'
    //   );
    //   this.Approvepp = this.CategoryMethod.filter(
    //     (data) => data == 'payoutPartnerApprove POST'
    //   );
    //   this.Rejectpp = this.CategoryMethod.filter(
    //     (data) => data == 'payoutPartnerReject POST'
    //   );
    //   this.TransactionColumn = this.CategoryMethod.filter(
    //     (data) => data == 'systemTransactionColumn GET'
    //   );
    //   this.msbColumnAlias = this.CategoryMethod.filter(
    //     (data) => data == 'mapColumnAlias POST'
    //   );
    //   this.deliveryCountry = this.CategoryMethod.filter(
    //     (data) => data == 'addDeliveringMethods POST'
    //   );
    //   this.payoutPlanAdd = this.CategoryMethod.filter(
    //     (data) => data == 'finalizePayoutPlan'
    //   );
    // }
    // this.changeDetectorRef.detectChanges();
    //       console.log(this.CategoryMethod);
    //     } else {
    //       // this.logout();
    //     }
    //     return user;
    //   })
    // );
  }
  Checked: boolean = false;
  byCategory(val: any) {
    if (val) {
      this.Checked = true;
      this.byCategoryValue = this.byCategoryValue.filter(
        (data: { identifier: string }) => data.identifier == 'SETTINGS'
      );
    }
    this.currentCountrySubject.next(this.byCategoryValue);
  }
  Error: any;
  handleError(error: HttpErrorResponse) {
    this.Error = error;
    // console.log(error);
    return throwError(error);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
  setChangedetector(changeDetectorRef: ChangeDetectorRef) {
    this.changeDetectorRef = changeDetectorRef;
  }
}
