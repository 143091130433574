import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { MsbModel } from '../models/msb.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { MsbHTTPService } from './msb-http/msb-http.service';
import { Injectable, OnDestroy } from '@angular/core';

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class MsbService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  msbLocalStorageToken = `LoginToken`;

  // public fields
  currentUser$: Observable<UserType>;
  currentCountry$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  currentCountrySubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(private msbHttpService: MsbHTTPService, private router: Router) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();

    this.currentCountrySubject = new BehaviorSubject<UserType>(undefined);
    this.currentCountry$ = this.currentCountrySubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    // const subscri = this.getCountry().subscribe();
    this.unsubscribe.push(subscr);
    // this.unsubscribe.push(subscri);
  }

  // public methods
  login(emailId: string, password: string): Observable<UserType> {
    this.isLoadingSubject.next(true);
    return this.msbHttpService.login(emailId, password).pipe(
      map((msb: MsbModel) => {
        const result = this.setMsbFromLocalStorage(msb);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      // catchError((err) => {
      //   console.error('err', err);
      //   return of(undefined);
      // }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    localStorage.removeItem(this.msbLocalStorageToken);
    localStorage.clear();
    this.router.navigate(['/msb/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserType> {
    const msb = this.getMsbFromLocalStorage();
    if (!msb || !msb.token) {
      return of(undefined);
    } else {
    }
    this.isLoadingSubject.next(true);
    return this.msbHttpService.getUserByToken(msb.token).pipe(
      map((user: UserType) => {
        if (user) {
          this.currentUserSubject.next(user);
          // console.log(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // list of country
  // getCountry(): Observable<UserType> {
  //   const msb = this.getAuthFromLocalStorage();
  //   if (!msb || !msb.token) {
  //     return of(undefined);
  //   }
  //   this.isLoadingSubject.next(true);
  //   return this.msbHttpService.getCountry(msb.token).pipe(
  //     map((user: UserType) => {
  //       alert('hi')
  //       if (user) {
  //         this.currentCountrySubject.next(user);
  //         console.log(user);
  //       } else {
  //         this.logout();
  //       }
  //       return user;
  //     }),
  //     finalize(() => this.isLoadingSubject.next(false))
  //   );
  // }

  // need create new user then login
  registration(user: any): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.msbHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.emailId, user.password)),
      // catchError((err) => {
      //   // console.error('err', err);
      //   return of(undefined);
      // }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string, sendOtpTo: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.msbHttpService
      .forgotPassword(email, sendOtpTo)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  otp(otp: string, email: string, password: string): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.msbHttpService
      .OTP(otp, email, password)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }
  MSB = 'msb';
  // private methods
  private setMsbFromLocalStorage(msb: MsbModel): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (msb && msb.token) {
      localStorage.setItem(this.msbLocalStorageToken, JSON.stringify(msb));
      localStorage.setItem(this.MSB, JSON.stringify(this.MSB));
      // console.log(msb);
      return true;
    }
    return false;
  }

  private getMsbFromLocalStorage(): MsbModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.msbLocalStorageToken);
      // console.log(lsValue);
      if (!lsValue) {
        return undefined;
      }

      const msbData = JSON.parse(lsValue);
      // console.log(msbData.token);
      return msbData;
    } catch (error) {
      // console.error(error);
      return undefined;
    }
  }
  getToken() {
    try {
      const lsValue = localStorage.getItem(this.msbLocalStorageToken);
      // console.log(lsValue);
      if (!lsValue) {
        return undefined;
      }

      const msbData = JSON.parse(lsValue);
      return msbData.token;
    } catch (error) {
      // console.error(error);
      return undefined;
    }
  }

  getRefreshToken() {
    try {
      const lsValue = localStorage.getItem(this.msbLocalStorageToken);
      // console.log(lsValue);
      if (!lsValue) {
        return undefined;
      }

      const msbData = JSON.parse(lsValue);
      return msbData.refreshToken;
    } catch (error) {
      // console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
