import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AsideMenuService } from './aside/aside-menu/aside-menu.service';
import { MsbDocumentsUploadService } from './components/MSB/msb-documents-upload/msb-documents-upload.service';
import { PPDocumentsUploadService } from './components/PP/pp-documents-upload/PP-documents-upload.service';
// export class AlwaysAuthGuard implements CanActivate {
//   constructor(private asideService: AsideMenuService) {}
//   canActivate() {
//     console.log('AlwaysAuthGuard');
//     if (this.asideService.Allow) {
//       return false;
//     }
//     return true;
//   }
// }
@Injectable({ providedIn: 'root' })
export class AlwaysAuthGuard implements CanActivate {
  constructor(
    public asideService: AsideMenuService,
    public msbDocumentsUploadService: MsbDocumentsUploadService,
    public ppDocumentsUploadService: PPDocumentsUploadService
  ) {}
  MSB = 'msb';
  ADMIN = 'admin';
  canActivate() {
    if (this.asideService.Checked == true) {
      return false;
    }
    if (this.msbDocumentsUploadService.Checked == true) {
      return false;
    }
    if (this.ppDocumentsUploadService.Checked == true) {
      return false;
    }
    // if (msbValue) {
    //   // console.log(currentmsbUser);
    //   // logged in so return true
    //   return false;
    // }
    // if (adminValue) {
    //   // console.log(AdmincurrentUser);
    //   // logged in so return true
    //   return false;
    // }
    // if (currentpartnerUser) {
    //   // console.log(currentpartnerUser);
    //   // logged in so return true
    //   return false;
    // }
    // alert('canLoad block!');
    return true;
  }
}
